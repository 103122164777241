@import './assets/styles/masterpass-form.scss';
@import './assets/styles/inset-border-radius.scss';
@import './assets/styles/custom-styles.scss';
@import './assets/styles/custom-logicflow.scss';

* {
    font-family: 'Montserrat' !important;
}

:root {
    // --topbar: #4e83e7;
    // --navbar: #ccae91; 
    --sidebar: #F6F6F680;
    // --header: #5daa5a;  
    // --sub-header: #926894; 
    // --carousel: #c2687e; 
    // --directory: #def09dcb; 
    // --options: #75e4accb;
    // --others1: #7480ebcb;
    // --others2: #74cfebcb;
    // --others3: #949791cb;
}

.border-grey {
    border-color: #828C96;
}

.router-content {
    position: absolute;
    left: 200px;
    transition: ease-in-out 0.4s;

    @media (max-width: 600px) {
        min-width: 85% !important;
    }

    @media (max-width: 960px) {
        min-width: 72%;
    }
    
    @media (min-width: 1140px) {
        min-width: 78%;
    }

    @media (min-width: 1320px) {
        min-width: 85%;
    }
    
    @media (min-width: 1800px) {
        min-width: 89%;
    }

    @media (min-width: 2000px) {
        min-width: 91%;
    }
}

.router-content.sidebar-closed {
    position: absolute;
    left: 80px;
    width: 94%;
    transition: ease-in-out 0.4s;

    @media (max-width: 960px) {
        width: 88%;
    }

    @media only screen and (min-width: 2000px) {
        width: 96%;
    }
}

@media screen and (max-width: 700px) {
    .router-content {
        left: 20px;
    }
}

@media screen and (max-width: 400px) {
    .router-content a {
        left: 20px;
    }
}

.project-box {

    margin: 20px 0;

    .list {
        text-align: start;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .list-group-item {
        padding: 5px 0 5px 0 !important;
        margin: 0 5px 0 20px !important;

        .pin-box {
            margin: 0 5px;
            border-right: 1px solid rgba(0, 0, 0, 0.315);
            padding: 0 5px;
        }
    }

    .list-group {
        margin-bottom: 20px !important;
    }
}

.search-box {
    background-color: var(--others2);
    width: 140px;
    height: 25px;
    position: relative;

    input {
        border-radius: 10px;
        border: none;
        height: 90%;
        width: 100%;
        text-indent: 10px;
        background-color: #F6F6F6;


        &::placeholder {
            font-size: 11px;
        }

        &:focus {
            border-radius: 0;
        }

    }

    img {
        opacity: 0.8;
        position: absolute;
        top: 5px;
        right: 5px;
        height: 17px;
    }


}

.function-box {
    background-color: var(--others1);
    justify-content: space-between;
    display: flex;

    .filter-tag-box {
        background-color: var(--others2);
        display: inline-flex;

        .filter-tags {
            background-color: #97BEFA;
            display: flex;
            border-radius: 10px;
            font-size: 10px;
            text-align: center;
            align-items: center;
            padding: 5px;
            margin: 0 10px 0 0;

            &>img {
                opacity: 0.5;
                height: 18px;
                width: 18px;
                margin: 0 0 0 5px;

                &:hover {
                    opacity: 1;
                }
            }
        }

    }

    .filter-search-box {
        display: flex;
        justify-content: flex-end;
        background-color: var(--others3);
        margin: 0 20px 0 0;

        .filter-box {
            color: #1976D2;
            padding: 5px;
            font-size: 12px;
            margin: 0 10px 0 0;
            cursor: pointer;
        }

    }

}

.dropdown {
    position: relative;
    display: inline-block;

    &-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 300px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 12px 16px;
        z-index: 1;
        color: black;
        top: 25px;
        right: 0px;

        &-categories {
            background-color: var(--others3);
            margin: 10px 0 10px 0;
        }

        .dropdown-options {
            display: flex;
            margin: 4px 0 0 0;
        }

    }
}

.dropdown:hover.filter-box .dropdown-content {
    display: block;
}

.dropdown-search {

    display: block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    color: black;
    top: 25px;
    right: -20px;
    font-size: 12px;

    .results {
        margin: 5px 0 5px 0;
        font-size: 12px;
        border-bottom: 1px solid #97BEFA;
    }


}

.modal-content {
    border-radius: 15px;
    font-family: 'Montserrat';

    h4 {
        font-size: 15px;
    }

    label {
        font-weight: bold;
        font-size: 12px;
    }

    input {
        // height: 25px !important;
        // padding: 5px 0 5px 0;
        text-indent: 5px;
    }
}

.modal-footer {

    button {
        font-size: 12px;

    }
}

.member-card {
    height: 27vh;
    width: 90%;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    position: relative;
    border: 1px solid grey;

    .kebab {
        position: absolute;
        top: 5px;
        right: 0;
    }

    .img-box {

        border-radius: 10px;
        padding: 15px 0 15px;

        img {
            object-fit: cover;
            height: 8vh;
            width: 8vh;
            border-radius: 50%;
        }

    }

    .function-box {
        display: flex;
        justify-content: space-between;
        width: 50%;

        img {
            height: 1.7vh;
        }
    }
}

.staff-info-box {
    width: 40%;
    padding: 0 20px 0 20px;
    margin-left: 100px;

    .info-header {
        width: 60%;

    }

    .img-box {

        border-radius: 10px;
        padding: 15px 0 15px;
        justify-content: center;
        display: flex;

        img {
            object-fit: cover;
            height: 8vh;
            width: 8vh;
            border-radius: 50%;
        }

    }


    .function-box {
        width: 40%;

        img {
            height: 1.7vh;
        }
    }
}

.info-body {
    .col-4 {
        padding: 0px !important
    }
}

.page {
    padding: 10px 4px 0px 4px;
    // min-width: 85vw;

    @media (max-width: 600px) {
        min-width: 85vw !important;
    }

    @media (max-width: 960px) {
        min-width: 72vw;
    }
    
    @media (min-width: 1140px) {
        min-width: 78vw;
    }

    @media (min-width: 1320px) {
        min-width: 85vw;
    }
    
    @media (min-width: 1800px) {
        min-width: 89vw;
    }

    @media (min-width: 2000px) {
        min-width: 91vw;
    }

    .page-header {
        font-family: 'Montserrat';
        margin: 10px 20px 10px 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(128, 128, 128, 0.281);

        h2 {
            font-weight: bold;
        }

        span {
            font-size: 15px;
        }

        .sub-header {
            justify-content: space-between;
            display: flex;
        }
    }

    .page-content {
        padding: 20px;
    }

}

.permissions-box {

    margin: 20px;
    margin-left: 120px;

    .col.fw-bold {
        padding: 0 !important
    }

    .tracking-box {
        border-radius: 10px;
        border: 1px solid rgba(128, 128, 128, 0.329);
        width: 60%;
        padding: 20px;


        .box-header {
            margin: 0 0 10px;
        }

        .sub-box {
            border-right: 1px solid rgba(128, 128, 128, 0.411);
        }
    }

    .other-box {
        width: 60%;

        .box-header {
            margin: 0 0 10px;
            padding: 0
        }

        .sub-box {
            border-radius: 10px;
            border: 1px solid rgba(128, 128, 128, 0.329);
            padding: 20px 32px 20px 32px;

            .col {
                padding: 0;
            }
        }
    }
}


.btn {
    font-family: 'Roboto';
}

.btn-block {
    width: 100%;
}

.btn-default:hover {
    background-color: #f8f9fa;
}

.btn-default:disabled {
    background-color: #f8f9fa;
    color: #505356;
    border: transparent;
}

.fs-7 {
    font-size: 0.8rem !important;
}

.fs-8 {
    font-size: 0.7rem !important;
}

.search-input-group {
    position: relative;
}

.search-input-group .search-bar {
    background-color: #f6f6f6;
    border: none;
}

.search-icon {
    right: 0;
    margin-right: 5%;
    margin-top: 1%;
    width: 20px;
    height: 20px;
    opacity: 54%;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#admin {
    @import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@400;700;900&family=Roboto&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400&display=swap');

    :root {
        // --topbar: #4e83e7;
        // --navbar: #ccae91; 
        --sidebar: #F6F6F680;
        // --header: #5daa5a;  
        // --sub-header: #926894; 
        // --carousel: #c2687e; 
        // --directory: #def09dcb; 
        // --options: #75e4accb;
        // --others1: #7480ebcb;
        // --others2: #74cfebcb;
        // --others3: #949791cb;
    }

    body {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none !important;
        // color: black !important;
        cursor: pointer;
    }


    li {
        list-style: none;
    }

    h1 {
        margin: 0;
        padding: 0;
    }

    .directory {
        background-color: var(--directory);
        padding: 20px 0 10px 0;
    }

    .topbar {
        display: flex;
        justify-content: flex-end;
        background-color: var(--topbar);
        height: 5vh;
        padding-top: 10px;
        margin-top: 10px;

        & ul {
            display: flex;
            margin-right: 20px;

        }

        & li.scale {
            margin: 0px 10px 0px 10px;

            &:hover {
                transform: scale(1.1);
            }

        }

        & img {
            height: 2vh;
            width: 2vh;
            opacity: 0.6;
        }

    }

    .navbar {
        display: flex;
        justify-content: flex-end !important;
        background-color: var(--navbar);
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        height: 5vh;

        & ul {
            display: flex;
            margin: 0 10px 0 0;
        }

        // & li {
        //     // margin: 0px 15px 0px 15px;
        // }

        & a {
            color: black;
        }

        &-options {
            height: 25px;
            background-color: var(--options);
            // min-width: 100px;
            margin-right: 20px;
            display: flex;
            justify-content: center;

        }

        &-options:hover {
            // transform: scale(1.05);
            // transition: 0.2s all;
            border-bottom: 2px solid rgb(106, 144, 214);
            text-shadow: 0 0 0.03px black;
        }

        &Toggle {
            display: none;
        }


    }

    .navbar-optionsToggle {
        border-bottom: 2px solid rgb(106, 144, 214);
        font-weight: bold;
        height: 25px;
        background-color: var(--options);
        margin-right: 20px;
        display: flex;
        justify-content: center;
    }


    .sidebar {
        height: 100%;
        width: 200px;
        /* this should be dynamic */
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        overflow-x: hidden;
        padding-top: 10px;
        justify-content: center;
        text-align: center;
        background-color: var(--sidebar);
        font-family: 'Roboto', sans-serif;
        transition: 0.5s all;

        & a {
            font-size: 15px;
            color: #1976D2;
            display: flex;
            align-items: center;
        }

        &-icons {
            height: 25px;
            width: 25px;
            vertical-align: middle;

            &-box {
                justify-content: center;
                display: flex;
                text-align: center;
                min-height: 40px;
                min-width: 40px;
                border-radius: 10px;
                margin-right: 10px;
                align-items: center;

                // :hover{
                //     box-shadow: 1px 2px 4px 2px #d3d1d1;
                // }
            }
        }

        &-iconsToggle {
            height: 25px;
            width: 25px;
            vertical-align: middle;
            // filter: invert(1);

            &-box {
                justify-content: center;
                display: flex;
                text-align: center;
                min-height: 40px;
                min-width: 40px;
                border-radius: 10px;
                margin-right: 10px;
                box-shadow: 1px 2px 4px 2px #d3d1d1;
                background-color: #003D79;
                align-items: center;
                margin-left: 16px
            }

        }

        &-options {
            text-align: start;
            padding: 10px 5px 10px 15px;
        }

        &-options:hover {

            >a>.sidebar-icons-box {
                box-shadow: 1px 2px 4px 2px #d3d1d1;
            }

            .sidebar-options-text {
                transform: scale(1.1);
                transition: 0.2s all;
            }

        }

        &-content {
            justify-content: center;
            background-color: var(--others1);
        }

        &-close {
            background-color: var(--others2);
            text-align: right;
            margin: 10px 15px;
        }

        &-close-icon {
            max-height: 20px;
            background-color: var(--others3);

            &:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        }

        & .logo-img {
            height: 150px;
        }

    }

    @media screen and (max-width: 700px) {
        .sidebar {
            width: 100%;
            height: auto;
            position: relative;
        }

        .sidebar a {
            float: left;
        }

        div.content {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 400px) {
        .sidebar a {
            text-align: center;
            float: none;
        }
    }

    .content {
        display: relative;
        font-family: 'Montserrat', sans-serif;
        transition: 0.5s all;
        padding: 20px 20px 40px 20px;
        background-color: var(--others1);

        @media (max-width:576px) {
            padding: 10px 10px 40px 10px;
        }

    }

    .header {
        background-color: var(--header);
        font-weight: bold;
        font-size: 30px;
    }

    .sub-header {
        font-weight: normal;
        font-size: 22px;
        background-color: var(--sub-header);
        display: inline-flex;
        color: black;
    }


    .directory-subtitle {
        font-family: 'Roboto';
        opacity: 0.8;
        font-size: 14px;
    }

    .overview {

        &-recently-edited,
        &-latest {
            background-color: var(--sub-header);
            padding: 10px 0 10px 0;
        }

        &-latest {
            // border-top: 1px solid rgba(128, 128, 128, 0.452);
        }

    }

    .carousel-item {
        text-align: center;
        background-color: var(--carousel);
        z-index: 0;


    }

    .card {


        &>img {
            position: relative;
            top: -30px;
            // z-index: 10;
            border-radius: 15% 1% 0%;
            // box-shadow: 1px 5px 5px #888888;
            height: 200px;
            min-width: 200px;
            object-fit: cover;
            border: 1px solid rgba(0, 0, 0, 0.103);
        }

        // &:hover {
        //     transform: scale(1.03);
        //     transition: 0.5s all;
        // }

        &-title {
            background-color: var(--others1);
            font-family: 'Montserrat', sans-serif;
            text-align: start;
            font-weight: bold;
            font-size: 13px;
        }

        &-description {
            font-size: 11px;
            background-color: var(--others2);
            text-align: start;
            width: 218px;
            height: 40px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;

            &-user {
                font-size: 11px;
                background-color: var(--others2);
                text-align: start;
                width: 218px;
                height: 40px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &-details {
            background-color: var(--others3);
            position: absolute;
            bottom: 4px;
            right: 18px;
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 10px;
            text-overflow: ellipsis;

            &-icon {
                background-color: var(--others1);
                display: flex;
                margin: 5px 5px 5px;

                &>img {
                    height: 18px;
                    margin-right: 5px;
                }
            }

            &-user {

                font-size: 12px;
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding: 0 7px 0 0;

                .card-num-section {
                    display: inline-flex;
                }
            }

        }

        &-grid {
            // background-color: palegoldenrod;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            margin: 20px;
            gap: 2% 2%;
            justify-items: center;
        }

        &-category {
            font-size: 10px;
            margin: -7px 0 5px 0;
            color: #1976D2;
            text-align: start;
        }
    }


    .star-inactive {
        filter: invert(0.7);
        height: 20px;

        // z-index: 10;
        &:hover,
        :focus {
            cursor: pointer;
        }

    }

    .star-active {
        filter: invert(92%) sepia(18%) saturate(4353%) hue-rotate(358deg) brightness(103%) contrast(105%);
        // filter: invert(43%) sepia(37%) saturate(964%) hue-rotate(174deg) brightness(88%) contrast(91%);
        height: 20px;

        // z-index: 10;
        :hover,
        :focus {
            cursor: pointer;
        }

    }

    // https://www.rgbtohex.net/
    // https://codepen.io/sosuke/pen/Pjoqqp

    .progress-bar {
        background-color: #49AC27 !important;
        border-radius: 15px !important;
        height: 10px !important;
        width: 190px;
    }

    .progress {
        border-radius: 15px !important;
        height: 10px !important;
        width: 190px;
        margin: 0 10px 0 0;
    }

    .progress-bar-box {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin: 7px 0 7px 0;
        position: relative;
    }

    .test {
        position: absolute;
        bottom: 2px;
        right: 10px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        filter: invert(1);
    }


    .carousel-control-next,
    .carousel-control-prev {
        width: 5% !important;
    }

    #example-icon-onclick {
        background-color: rgba(0, 61, 121, 0.8);
    }

    // card testing starts here --------

    // .carousel-item .card .card-content.special-card-1{
    //     position: relative;
    // 	display: flex;
    // 	justify-content: center;
    // 	align-items: center;
    // 	flex-direction: column;
    // 	transition: 0.5s;

    //     & .card-contentBox{
    //         margin-top: 10px;
    //         margin-right: 20px;

    //         & .card-imgBox > img{
    //             border-radius: 15% 1% 0%;
    //         }
    //     }
    // }

    // #special-card-2 {
    //     border-radius: 10px;
    //     min-height: 230px;
    //     object-fit: cover;
    //     border: 1px solid rgba(0, 0, 0, 0.103);
    // }

    // .card.special-card-0 {
    //     text-align: start;

    // }

    // // determines how much image slides out of card ------------

    // .carousel-item .card:hover .card-content.special-card-1{
    // 	transform: translateY(-30px);
    // }

    // .carousel-item .card .card-content .imgBox img{
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	width: 100%;
    // 	height: 100%;
    // 	object-fit: cover;
    //     height: 250px;
    // }

    // .carousel-item .card-content .card-details.special-card-3{
    // 	position: absolute;
    // 	bottom: -50px;
    // 	display: flex;
    //     transform: translateY(40px);
    //     transition: 0.5s;
    //     opacity: 0;
    //     // background-color: rgb(124, 26, 26);
    //     flex-direction: column;
    //     align-items: flex-end;

    // }


    // .carousel-item .card-content:hover .card-details.special-card-3{
    // 	transform: translateY(20px);
    // 	opacity: 1;
    // }

    // .card.special-card-0{
    //     height: 350px;
    //     box-shadow: none;
    // }

    // .card.special-card-0:hover{
    //     transform: scale(1);
    // }


    // .special-card-4 {
    //     // background-color: palegoldenrod;
    //     display: flex;
    //     margin: 10px 0 0 0;
    // }

    // .special-card-4 img{
    //     // background-color: paleturquoise;
    //     height: 18px;
    //     display: flex;
    //     margin: 0 5px;
    // }

    // modal styling starts here ------

    .sidebar-modal {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 20;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
        margin: auto;

        &-content {
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            border: 1px solid #888;
            width: 60%;
            font-family: 'Roboto', sans-serif;
            text-align: end;
            border-radius: 20px;
            justify-content: center;

        }

        &-icons {
            height: 50px;
            width: 50px;
            vertical-align: middle;

            &-box {

                justify-content: center;
                display: flex;
                text-align: center;
                height: 80px;
                width: 80px;
                border-radius: 10px;
                align-items: center;
                box-shadow: 1px 2px 4px 2px #d3d1d1;
            }
        }

        &-close {
            color: #aaaaaa;
            position: relative;
            left: 0px;
            font-size: 28px;
            font-weight: bold;

            &:hover,
            :focus {
                color: #000;
                text-decoration: none;
                cursor: pointer;
            }
        }

        &-list {

            text-align: center;
            margin-top: 20px;

            &-item {

                font-size: 30px;
                border-bottom: 1px solid rgba(128, 128, 128, 0.2);
                padding: 0 0 10px 0;
            }

            &>ul {
                padding-left: 0 !important;
                margin-bottom: 20px !important;
            }
        }

        &-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-bottom: 20px;
            gap: 20px;
            justify-items: center;
        }

    }

    #toggle-mobile-menu {
        position: absolute;
        top: 25px;
        left: 25px;
        height: 3vh;
        display: none;

        &>img {
            height: 100%;
        }
    }

    // All courses styling starts here ------------
    .function-box {
        background-color: var(--others1);
        justify-content: space-between;
        display: flex;
    }

    .filter-tag {

        &-box {
            background-color: var(--others2);
            display: inline-flex;
        }


    }

    .filter-tags {
        background-color: #97BEFA;
        display: flex;
        border-radius: 10px;
        font-size: 10px;
        text-align: center;
        align-items: center;
        padding: 5px;
        margin: 0 10px 0 0;

        &>img {
            opacity: 0.5;
            height: 18px;
            width: 18px;
            margin: 0 0 0 5px;

            &:hover {
                opacity: 1;
            }
        }
    }

    .filter-search-box {
        display: flex;
        justify-content: flex-end;
        background-color: var(--others3);
        margin: 0 20px 0 0;
    }

    .filter-box {
        color: #1976D2;
        padding: 5px;
        font-size: 12px;
        margin: 0 10px 0 0;
        cursor: pointer;
    }

    .search-box {
        background-color: var(--others2);
        width: 140px;
        height: 25px;

        &>input {
            border-radius: 10px;
            border: none;
            height: 90%;
            width: 100%;
            text-indent: 10px;
            background-color: #F6F6F6;

            &::placeholder {
                font-size: 11px;
            }

            &:focus {
                border-radius: 0;
            }
        }

        &>img {
            opacity: 0.8;
            position: relative;
            top: -23px;
            right: -120px;
            height: 17px;
        }
    }

    // clocking module search bar START
    .search-input-group {
        position: relative;

        .search-bar {
            background-color: #f6f6f6;
            border: none;
        }

        .search-bar:focus {
            outline: none;
        }
    }

    .search-icon {
        right: 0;
        margin-right: 5%;
        margin-top: 1%;
        width: 20px;
        height: 20px;
        opacity: 54%;
    }

    // clocking module search bar END


    .dropdown {
        position: relative;
        display: inline-block;

        &-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            padding: 12px 16px;
            z-index: 1;
            color: black;
            top: 25px;
            right: 0px;

            &-categories {
                background-color: var(--others3);
                margin: 10px 0 10px 0;
            }
        }
    }


    .dropdown-search {

        display: block;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 12px 16px;
        z-index: 1;
        color: black;
        top: 25px;
        right: -20px;
        font-size: 12px;

        &-results {
            margin: 5px 0 5px 0;
            font-size: 12px;
            border-bottom: 1px solid #97BEFA;
        }


    }

    .dropdown:hover.filter-box .dropdown-content {
        display: block;
    }


    .dropdown-options {
        display: flex;
        margin: 4px 0 0 0;
    }

    .checkbox-input {
        align-items: center;
        display: flex;
    }

    .checkbox-label {
        margin: 0 0 0 4px;
        align-items: center;
        display: flex;
    }

    .all-courses-header-box {
        margin: 25px 15px 15px 0;
        justify-content: space-between;
        display: flex;
        align-items: baseline;
        font-size: 13px;
    }

    .all-course-modal {
        // display: none; 
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */

        &-content {
            background-color: #fefefe;
            margin: auto;
            width: 60%;
            font-family: 'Roboto', sans-serif;
            text-align: end;
            border-radius: 15px;
            height: 90%;
            box-shadow: 2px 4px 8px 4px rgb(121, 120, 120);

        }


        &-header {
            border-radius: 15px 15px 0 0;
            height: 80px;
            background-color: #5B78A1;
            justify-content: space-between;
            display: flex;

            &-info-box {
                align-items: center;
                display: flex;
                margin: 0 0 0 20px;
                gap: 30px;
                font-family: 'Montserrat';
            }
        }

        &-title {
            font-size: 25px;
            color: white;
            font-weight: bold;
        }

        &-category {
            background-color: white;
            align-items: center;
            display: flex;
            border-radius: 20px;
            height: 20px;
            padding: 10px;
            font-size: 10px;
            font-weight: bold;
        }

        &-star {
            align-items: center;
            display: flex;

            >img {
                height: 20px;

                &:hover,
                :focus {
                    cursor: pointer;
                }

            }
        }

        &-close {
            color: #444444;
            font-size: 28px;
            font-weight: bold;
            align-items: center;
            display: flex;
            margin: 0 20px 0 0;

            &:hover,
            :focus {
                color: #000;
                text-decoration: none;
                cursor: pointer;
            }
        }

        &-creator {

            margin: 0 0 0 10px;

            &:hover,
            :focus {
                cursor: pointer;
            }

            >img {
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
                margin: 0 5px 0 0;
            }

            &-box {
                font-size: 12px;
                margin: 0 0 0 40px;
                display: flex;
            }

        }

        &-body {
            margin: 20px;

            &-description {
                text-align: start;
            }

            &-header {
                display: flex;
                font-family: 'Montserrat';
                margin: 20px 0 0 0;
                justify-content: space-between;

                &-box {
                    display: flex;
                }

                &-title {
                    font-size: 20px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }

                &-info {
                    font-size: 15px;
                    align-items: center;
                    display: flex;
                    margin: 0 0 0 30px;
                }

                &-button {
                    background-color: #20C997;
                    align-items: center;
                    text-align: center;
                    display: flex;
                    border-radius: 5px;
                    color: white;
                    padding: 10px;
                    font-weight: bold;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.05);
                        transition: 0.5s;
                    }

                }

            }

            .accordion {

                &-box {
                    margin: 20px 0 10px 0;
                    overflow-y: scroll;
                    height: 500px;
                }

                &-item {

                    &-header {
                        padding: 10px;
                        display: flex;
                        gap: 20px;
                        font-family: 'Montserrat';
                        color: black;

                        &-title {
                            font-size: 18px;
                            font-weight: bold;
                            text-align: start;
                            margin: 10px 0 0 0;
                        }

                        &-description {
                            font-size: 14px;
                            text-align: start;
                            margin: 10px 0 0 0;
                        }

                        &-info {
                            text-align: end;
                            margin: 35px 0 0 0;
                        }

                        &-box {
                            width: 900px;
                        }
                    }

                    &-img {
                        height: 110px;
                        border: 1px solid rgba(128, 128, 128, 0.315)
                    }

                    &-content {
                        display: grid;
                        grid-template-columns: 0.5fr 2fr 1fr;
                        height: 50px;
                        background-color: #e0eafa;
                        border-bottom: 1px solid rgba(131, 131, 131, 0.466);

                        &-icons {
                            margin: 0 0 0 10px;
                            align-items: center;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            margin: 0 0 0 40px;

                            .circle-icon {
                                height: 15px;
                                opacity: 0.8;
                            }

                            .tick-icon {
                                height: 30px;
                                opacity: 0.8;
                            }

                            .quiz-icon {
                                height: 15px;
                                opacity: 0.8;
                            }

                            .video-icon {
                                height: 20px;
                                opacity: 0.8
                            }
                        }

                        &-title {
                            margin: 0 0 0 40px;
                            align-items: center;
                            display: flex;
                            justify-content: start;
                        }

                        &-duration {
                            align-items: center;
                            display: flex;
                            font-size: 13px;
                        }

                    }

                    &-content:hover {
                        cursor: pointer;
                        background-color: #cbddfa;
                    }

                }

            }

            .mat-expansion-panel-header {
                height: 125px;
                padding: 0 10px 0 10px;
            }

            .mat-expansion-panel-body {
                padding: 0;
            }
        }

        .test {
            overflow: scroll;
        }

    }

    // All courses styling ends here ------------

    .manage {

        &-btn-box {
            display: flex;
            gap: 10px;
            justify-content: end;
        }
    }

    .mat-drawer-content {
        height: 500px !important;
    }




    @media (max-width: 1800px) {
        .card-grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    @media (max-width: 1500px) {
        .card-grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }






    @media (max-width: 1200px) {

        .card-grid {
            grid-template-columns: 1fr 1fr;
        }

        .carousel-item {

            .card {
                width: 200px;
                height: 300px;

                >img {
                    min-height: 178px;
                    min-width: 178px;
                }

                &-body {
                    margin-top: -30px;
                    padding: 5px 0 5px 0;
                    width: 99%;
                }

                &-title {
                    font-size: 16px;
                    font-weight: bold;
                }

                &-description {
                    font-size: 12px;
                    height: 50px;
                }

            }
        }

        // .sidebar {

        //     &-icons {

        //     height: 50px;
        //     width: 50px;
        //     margin: 0 !important;

        //     }

        //     &-icons-box {
        //         height: 70px;
        //         width: 70px; 
        //         margin: 0 !important;
        // need to use justify-self for css grids
        //         justify-self: center; 
        //     }

        //     &-modal-list{
        //         font-size: 25px;
        //         font-family: 'Roboto', sans-serif;

        //         &-item {
        //             margin-top: 25px;
        //         }
        //     }

        //     &-modal-close {
        //         font-size: 45px;
        //         margin-right: 10px;
        //     }
        // }

        .topbar {
            display: flex;
            justify-content: flex-end;
            background-color: var(--topbar);
            height: 5vh;
            padding-top: 10px;

            & ul {
                display: flex;
                margin-right: 40px;
            }

            & li {
                margin: 0px 10px 0px 10px;
            }

            &>svg {
                height: 30px;
            }

            & img {
                height: 2vh;
                width: 2vh;
            }
        }


        .sub-header {
            font-size: 25px;
        }

        .directory {
            font-size: 18px;
        }

        .carousel-item {

            .card {
                width: 200px;
                height: 300px;

                >img {
                    min-height: 178px;
                    min-width: 178px;
                }

                &-body {
                    margin: -20px 0 0 0;
                    padding: 5px;
                    width: 99%;
                }

                &-title {
                    font-size: 16px;
                    font-weight: bold;
                }

                &-description {
                    font-size: 12px;
                    height: 50px;
                }

            }
        }

    }



    @media (max-width: 1000px) {

        .card-grid {
            grid-template-columns: 1fr 1fr;
        }

        .content {
            margin-left: 0px;
        }

        .sidebar-modal {

            &-content {
                min-height: 75%;
                width: 60%;
            }

            &-icons {
                height: 40px;
                width: 40px;

                &-box {
                    height: 60px;
                    width: 60px;
                }
            }

            &-close {
                font-size: 40px;
            }

            &-list {
                &-item {
                    font-size: 25px;
                }
            }

            &-grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin-bottom: 20px;
                gap: 20px;
                justify-items: center;
            }

        }


    }

    @media (max-width: 600px) {

        .card-grid {
            grid-template-columns: auto;
            margin-top: 20px 0 0 0;
            gap: 1% 0;
        }
    }

    @media (max-width: 500px) {

        .filter-tags {
            display: none
        }

        .sidebar-modal {

            &-content {
                height: 75%;
                min-width: 300px;
            }
        }



    }

    .modal {
        background: rgba(0, 0, 0, .3);

        &-body {
            color: black;
        }
    }


    // styling for all modal forms and question forms STARTS HERE
    .modal-content,
    .question,
    .new-question-form {
        border-radius: 10px !important;
        position: relative !important;

        label {
            font-weight: bold;
            font-size: 14px;
            width: 23%;
            font-family: 'Montserrat';
        }

        input,
        textarea,
        select {
            border-radius: 4px;
            background-color: rgb(196, 196, 196, 0.2);
            border: none;
            width: 63%;
            height: 31px;
            padding: 7px;
            font-family: 'roboto' !important;
            font-size: 14px !important;
        }

        input[type=file] {
            width: 35%;
        }

        select {
            width: 50%;
        }

        textarea {
            height: 100px;
        }
    }

    // styling for all modal forms and question forms ENDS HERE

    .modal-header {
        border-bottom: 0 none !important;
        font-family: 'Montserrat';
    }

    .modal-footer {
        border-top: 0 none !important;
    }

    .alert {
        font-size: 12px;
    }

    .manage {

        &-header {
            font-size: 20px;
            display: flex;
            align-items: center;

            &-description {
                font-size: 14px;
                margin: 0 0 10px;
            }
        }

        &-course {

            font-family: 'Inter', sans-serif;

            &-shape {
                width: 62%;
                border-radius: 15px;
                background-color: #5B78A1;
                height: 70vh;
                position: absolute;
                padding: 20px;
                top: 100px;
            }

            &-dropdown {
                position: absolute;
                right: 50px;
                top: 200px;
            }

            &-section {

                &-shape {

                    position: relative;
                    margin: 17vh 0 0 40px;

                }
            }
        }

        &-section {
            padding: 30px;
            border-radius: 15px;
            background-color: #F3F8FF;

            &-box {
                display: grid;
                grid-template-columns: 4fr 1fr;
            }

            &-body {
                margin: 30px 30px 0 0;
            }
        }
    }

    .forms-modal {

        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 20;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
        margin: auto;

        &-content {
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            border: 1px solid #888;
            width: 40%;
            font-family: 'Roboto', sans-serif;
            border-radius: 20px;
            animation-name: modalOpen;
            animation-duration: 1s;
        }

        &-title {
            font-size: 20px;
            color: black;
        }

        &-header {
            justify-content: space-between;
            display: flex;
        }

        &-body {
            margin: 20px 0 0 0;
        }

    }

    .question {
        background-color: white;
        padding: 10px;
        border-radius: 4px;
        font-family: 'Roboto' !important;
        margin: 0 0 10px 0;

        &-title {
            padding: 10px;
        }

        input {
            height: 25px !important;
            padding: 5px 0 5px 0;
            text-indent: 5px;
        }


        input[type=checkbox],
        input[type=radio] {
            width: 17px
        }

        input[type=number] {
            width: 40px
        }

        label {
            font-weight: normal;
            font-family: 'Roboto';
        }

        &-update-button,
        &-edit-button {
            text-align: end;
        }

        &-options {
            align-items: center;
            display: flex;
            gap: 10px;
            font-size: 15px;
            margin: 5px;

            &-box {
                padding: 5px 10px 5px 10px;

                img {
                    height: 18px;

                }
            }
        }

        &-submit {

            &-box {
                display: flex;
                justify-content: end;
                align-items: center;
                gap: 10px;
            }

            &-btn {
                background-color: #97BEFA;
                border: none;
                border-radius: 5px;
                padding: 10px;
                font-size: 15px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background-color: #7aafff;
                }
            }
        }

        &-modal-item {
            margin: 10px 0;
            color: black;
        }

    }

    .new-dropdown {
        display: flex;
        justify-content: end;
    }

    .banner {
        width: 100%;
        height: 200px;
        display: inline-block;
        border-radius: 10px;
        position: relative;

        &-detail {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0 0 5px 15px;
            font-size: 20px;
            font-family: 'inter';
            font-weight: bold
        }

        &-title {
            display: flex;
            align-items: center;
        }

        h5 {
            font-size: 15px;
        }

        >img {
            height: 200px;
            width: 100%;
            object-fit: cover;
            object-position: bottom;
            border-radius: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
        }


    }



    @keyframes modalOpen {
        from {
            opacity: 0
        }

        to {
            opacity: 1;
        }
    }

    .quiz-header {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px;

        &-item {
            display: flex;
            align-items: center;
        }

        button {
            font-family: 'Roboto' !important;
            font-size: 13px !important;
        }
    }

    .course-info {
        width: 48%;
        block-size: 736px;
        border-radius: 15px;
        z-index: -1;
        position: absolute;
    }

    .course-info-header {
        font-weight: bold !important;
        font-size: 20px !important;
    }

    .course-info-desc {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
    }

    .course-data {
        border-radius: 15px;
        // margin-top: 125px;
        // margin-left: 50px;
    }



    // CLOCKING styling START

    .clocking-name {
        vertical-align: middle;
    }

    .clocking-name:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    // CLOCKING styling END

}